<!--
 * @Author: chenlin 891635769@qq.com
 * @Date: 2022-08-04 18:26:03
 * @LastEditors: chenlin 891635769@qq.com
 * @LastEditTime: 2022-08-11 11:46:13
 * @FilePath: \_web_portal\src\views\onlineLearning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main pageMain">
    <Title></Title>
    <div class="head-margin"></div>
    <div class="row-cont">
      <el-row class="fast-row">
        <el-col :span="24">
          <p>{{exam.name}}</p>
        </el-col>
      </el-row>
      <el-row class="two-row">
        <el-col :span="3">
          <p class="right-title">限时{{exam.durationM}}分钟</p>
        </el-col>
        <el-col :span="6"><p>&nbsp;</p></el-col>
        <el-col :span="9">
          <p class="left-title">满分{{exam.sumScore}}分</p>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="24">
          <div class="title-color">
            <div class="icon-title">
              <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/05171508-0abd-4a7d-bc7e-f7e628ccd9d0.png" alt="" />
              <span style="color:red;">{{ moment.utc(time).format('HH:mm:ss') }}</span>
            </div>
            <div class="icon-title2" @click="open">
              <img src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/52c3a81f-b330-4b9f-80b5-ecca4da708e6.png" alt="" />
              <span>交卷</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- <el-radio v-model="radio"></el-radio>  -->
      <el-row :gutter="0">
        <el-col :span="24">
          <div class="choice-question">
            <!-- <p>一、选择题 (每题2分 每题2分，共11题/22分)</p> -->
            <ol>

              <li class="fast-title" v-for="(item,i) in examTitleList" :key="item.id+'_'+i" >
                <!-- {{item.title}} &nbsp;&nbsp; -->
                <!-- ({{examTitleType(item.title_type)}} : {{item.score}}分) -->
                <div v-if="item.title_type != '4'">
                  {{ item.title }} &nbsp;&nbsp;
                </div>
                <div v-else>
                  <span v-for="(pack, info) in item.packData" :key="info">
                    <span v-html="pack.question"></span>
                    <span v-if="pack.flag == '0'">
                      <input
                        type="text"
                        maxlength="50"
                        style="width: 76px; height: 25px; margin: 0 8px"
                        v-model="pack.input"
                      />
                    </span>
                  </span>
                  &nbsp;&nbsp;
                </div>
                <ul type="none" class="child-ol">
                  <img v-if="item.images" :src="item.images" alt="" width="200">
                  <template v-if="item.title_type=='1' || item.title_type=='2'">
                  <li class="title-hard"  v-for="(o,index) in item.data" :key="item.id+'_'+i+'_'+index">
                    <el-checkbox v-model="o.select" @change="selectRadio1(item.data,item.title_type,o)"></el-checkbox>
                    <!-- <span class="radio-select" :class="o.select?'selected-radio':''" @click="selectRadio(item,o)"></span> -->
                    {{o.no}}.{{o.text}}
                  </li>
                  </template>
                  <template v-if="item.title_type=='3'">
                  <li class="title-hard" v-for="(o,index) in item.data" :key="item.id+'_'+i+'_'+index">
                    <el-checkbox v-model="o.select" @change="selectRadio2(item.data,item.title_type,o)"></el-checkbox>
                    <!-- <span class="radio-select" :class="o.select?'selected-radio':''" @click="selectRadio(item,o)"></span> -->
                    {{o.text}}
                  </li>
                  </template>
                  <!--
                  <li class="title-hard"><span class="radio-select" @click="selectRadio()"></span>抽气（风）道检查记录</li>
 -->
                </ul>
                <!-- <el-input class="textareaCss" v-if="item.title_type=='4'" :rows="4" type="textarea" v-model="item.answer" placeholder="请输入答案, 多个填空请用|分割,不要输入无关字符"></el-input> -->
              </li>


            </ol>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="15">
          <div class="icon-title2 icon-btn" @click="open">
              <span>交卷</span>
        </div>
        </el-col>
      </el-row>
      <div class="result_box" v-show="dialogVisible">

        <div class="result">
          <div class="close-hide" style="text-align:right; font-size:24px">
            <i class="el-icon-close" @click="goBack"></i>
          </div>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-row>
                <el-col :span="16"><h4 class="result_exam_name">{{exam.name}}</h4></el-col>
                <el-col :span="8"><div class="result_sumScore">{{score}}</div></el-col>
              </el-row>
            </div>
            <div  class="text item">
              <el-row>
                <el-col :span="12"><p class="center_ result_time">{{(exam.durationM-(time/1000/60)).toFixed(0)}}</p></el-col>
                <el-col :span="12"><p class="center_ result_accuracy ">{{(score/exam.sumScore*100).toFixed(0)}}%</p></el-col>
              </el-row>
              <el-row>
                <el-col :span="12"><p class="center_ result_time_title" >作答分钟数</p></el-col>
                <el-col :span="12"><p class="center_ result_time_title">正确率</p></el-col>
              </el-row>
            </div>
          </el-card>
          <img class=" jj left_jj " v-for="i in 2" :key="i" src="@/assets/course/jj.png">

          <el-card class="box-card">
            <div  class="text item">
              <h3 class="center_">错题集合</h3>
              <div class="error_list_box">
                <ol>
                  <li class="error_list_item" v-for="item in errorExamTitleList" :key="item.id">
                    <p>{{item.title}}</p>
<!--                     <ul type="none" class="child-ol">
                      <li class="title-hard"><span class="radio-select selected-radio" @click="selectRadio()"></span>抽气（风）道检查记录</li>
                    </ul> -->
                    <p class="result_text">正确答案是:{{item.result}}</p>
                  </li>
                </ol>

              </div>
              <el-empty v-if="errorExamTitleList.length===0" description="没有错题"></el-empty>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
import Title from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/foot/foot.vue'
import moment from 'moment'
import { getExamDetail, getExamTitleInfoByExamId,jcExamResultAdd } from '@/api/exam/exam'
export default {
  name: '',
  data() {
    return {
      moment,
      query: this.$route.query,
      radio: '1', // 选择题
      answer: '',
      time: 3600000,
      examTitleList: [], // 考题
      exam: {},
      dict: [],
      jc_exam_title_type: [],
      dialogVisible: false,
      errorExamTitleList: [],
      score: 0,
      setIntervalId:0,
      addExamResultQuery: {
				jcExamId: '', //试卷id
				jcUserId: '',
				score: '' //分数
			}
    }
  },
  watch: {},
  props: {},
  components: {
    Title,
    Footer,
  },
  computed: {},
  created () {
    this.addExamResultQuery.jcUserId = localStorage.getItem('userId')
    this.addExamResultQuery.targetId = localStorage.getItem('userId')
    this.addExamResultQuery.userType = this.query.value
    this.addExamResultQuery.indexId = this.query.indexId
    console.log(this.query.id);
    var  examId = this.query.id
    getExamDetail({ id: examId }).then(res => {
      console.log('试卷', res);
      if (res.success) {
        this.exam = res.data
        this.time = Number(this.exam.durationM) * 60 * 1000
        this.addExamResultQuery.code = this.query.code
        this.addExamResultQuery.jcExamId = res.data.id
      }
    })

    getExamTitleInfoByExamId({ examId: examId }).then(res => {
      console.log('试题', res);
      if (res.success) {
        res.data.forEach(e => {
          e.data = JSON.parse(e.optionss)
        })

        this.examTitleList = res.data
        this.domRender(this.examTitleList);
        console.log(res.data);
      }
    })

    this.dict = JSON.parse(localStorage.getItem('dict'))

    if (this.dict) {
      this.jc_exam_title_type = this.dict.filter(i => i.code == 'jc_exam_title_type')[0].children
    }
    console.log(this.jc_exam_title_type);

    this.getTimer()

  },
  mounted() {},
  methods: {
    // 提交考试分数
    _jcExamResultAdd() {
      this.addExamResultQuery.score = this.score
      jcExamResultAdd(this.addExamResultQuery).then(res => {

      })
    },
    goBack(){
      this.$router.go(-1)
    },
    examTitleType (code) {
      var obj = this.jc_exam_title_type.find(e => e.code === code)
      if (obj) {
        return obj.name
      } else {
        return ''
      }
    },
    getTimer() {
      var id = setInterval(() => {
        this.time -= 1000
        if (this.time == 0) {
          clearInterval(id)
          this.handPapers()
        }
      }, 1000)
      this.setIntervalId=id
    },
    open(){
        this.$confirm('为确保考试真实性,仅可提交一次,是否提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           this.handPapers()
        }).catch(() => {
        });
    },
    getTextByJs(arr) {
      let str = "";
      for (let item of arr) {
        console.log(item)
        if(item.input){
          str += item.input + "|";
        }
      }
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }
      return str;
    },
    domRender(str) {
      str.map(item => {
        if (item.title_type == "4") {
          let strData = item.title;
          let data = this.$global.handTK(strData);
          item.packData = data;
        }
      });
    },
    handPapers () {
      clearInterval(this.setIntervalId)
      this.errorExamTitleList = []
      this.score=0

      console.log(this.examTitleList);
      this.examTitleList.forEach(e => {
        if (e.title_type === '1' || e.title_type === '2' || e.title_type === '3') {
          var result = ''
          e.data.forEach(d => {
            if (d.select) {
              if (e.title_type === '1' || e.title_type === '2') {
                result += d.no
              } else if (e.title_type === '3') {
                result += d.text
              }

            }
          })
          e.valid = result === e.result
        } else if (e.title_type === '4') {
          let answer = this.getTextByJs(e.packData)
          e.valid = answer === e.result
          // e.valid = e.answer === e.result
        }
        if (!e.valid) {
          this.errorExamTitleList.push(e)
        } else {
          this.score+=e.score
        }

      })
      console.log(this.errorExamTitleList, this.score);
      if(this.score >= 0) {
        this._jcExamResultAdd()
      }
      // this.dialogVisible = true
      this.goBack()
    },
    selectRadio1 (item, type, o) {
      if (type =='1' || type=='3') {
        item.map(e => {
          e.select = false
        })
      } else {
        if(!o.hasOwnProperty('select')){
          o.select = false
        }else{
          o.select = !o.select
        }
      }
        o.select = !o.select
    },
    selectRadio2 (item, type, o) {
      if (type =='1' || type=='3') {
        item.map(e => {
          e.select = false
        })
      }
        o.select = !o.select
    },
    // selectRadio (item, o) {
    //   if (item.title_type =='1' || item.title_type=='3') {
    //       item.data.map(e => {
    //       e.select=false
    //     })
    //   }
    //   // console.log(item,o);
    //   o.select = !o.select
    // },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
input:focus {
  outline: none;
  border-radius: 3px;
  border: 2px solid #029aff;
}
::v-deep .el-checkbox__inner::after{
  left: 6px;
  top: 2px;
}
::v-deep .el-checkbox__inner{
  width: 17px;
  height: 17px;
  border-radius: 35px;
  margin-right: 20px;
}
.result_box{
  .result{
    position: relative;
    .close-hide{
      position: absolute;
      top: 2px;
      right: 4px;
    }
  }
}

.error_list_item{
  margin-bottom: 10px;
}

.result_text{
  margin-top: 5px;
  background-color: #eee;
}

.jj{
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left:4vw;
    margin-right:4vw;
}


.center_{
    text-align: center;
}

.result_time_title{
  margin: 20px 0px 20px;
}

.result_time{
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 153, 255, 1);
}

.result_accuracy{
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 153, 255, 1);
}

.el-dialog{
  background-image:url('~@/assets/course/result_bg.png');
}

.result_box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background-color: rgb(12 12 12 / 46%);
    z-index: 99999;
}

.result{
  margin-top: 4vw;
  width: 20vw;
  margin-left: 40vw;
  min-height: 60vh;
  background-image:url('~@/assets/course/result_bg.png');
  padding: 30px 20px 10px;
}

.result_exam_name{
  text-align: center;
}

.result_sumScore{
  font-size: 46px;
  color:red;
}
.c {
  width: 1320px;
  margin: 0 auto;
}

p {
  margin: 0;
  padding: 0;
}

.pageMain {
  background-color: rgba(235, 236, 237, 1);
  // height: 400px;
  // border: 1px solid red;
}
.head-margin {
  height: 80px;
  margin-bottom: 30px;
}
.row-cont {
  border-radius: 4px;
  padding-top: 60px;
  width: 1200px;
  margin: 0 auto;
  min-height: 400px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

  .fast-row {
    height: 41px;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: rgba(51, 51, 51, 1);
  }
  .two-row {
    margin-top: 36px !important;
    height: 26px;
    font-size: 20px;
    font-weight: 400;
    // color: rgba(102, 102, 102, 1);
    color: red;
  }
  .right-title {
    // text-align: right;
    padding-right: 20px;
    padding-left: 20px;
  }
  .left-title {
    text-align: left;
    padding-left: 20px;
  }
  .title-color {
    margin-top: 36px;
    width: 1200px;
    height: 50px;
    opacity: 0.8;
    background: rgba(235, 243, 247, 1);
  }
  .icon-title {
    display: inline-block;
    margin-left: 81px;
    margin-top: 16px;
    width: 260px;
    // border: 1px solid red;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      margin-left: 15px;
      position: relative;
      top: -3px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 153, 255, 1);
    }
  }
  .icon-title2 {
    cursor: pointer;
    display: inline-block;
    float: right;
    margin-right: 58px;
    margin-top: 10px;
    width: 120px;
    // border: 1px solid red;
    img {
      width: 39px;
      height: 30px;
    }
    span {
      margin-left: 23px;
      position: relative;
      top: -6px;
      font-size: 20px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }
  }
  .icon-btn{
    text-align: center;
    background-color: #029aff;
    height: 40px;
    line-height: 50px;
    color: #fff;
    margin-bottom: 20px;
    border-radius: 3px;
    span{
      margin-left: 0;
      color: #fff;
    }
  }

  .choice-question {
    // height: 26px;
    margin-bottom: 60px;
    padding-left: 41px;
    margin-top: 34px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  .fast-title {
    // height: 28px;
    margin: 30px 0px;
    // margin-right: 40px;
    width: 1000px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .title-hard {
    position: relative;
    margin: 15px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: rgba(102, 102, 102, 1);
    text-align: left;
  }
  .child-ol {
    // margin-left: 30px;
  }
  .radio-select {
    z-index: 6;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    left: -50px;
    top: 6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid rgba(222, 222, 222, 1);
  }
  .selected-radio {
    width: 18px;
    height: 18px;
    left: -50px;
    border: none;
    z-index: 10;
    background-color: #029aff;
    // background: url('https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/52c3a81f-b330-4b9f-80b5-ecca4da708e6.png');
    // background-repeat: no-repeat;
    // background-size: 100%;
    // background: url(https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/52c3a81f-b330-4b9f-80b5-ecca4da708e6.png) no-repeat;
  }
  .textareaCss {
    margin-top: 20px;
    position: relative;
    left: -14px;
  }
}
.floor-btn{
  width: 1200px;
  background-color: #fff;
}
</style>
